import "../components/templates/BlogPost.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import Content, { HTMLContent } from "../components/Content";

import ArticleCard from "../components/partials/ArticleCard/ArticleCard.js";
import BreadCrumb from "../components/partials/BreadCrumb/BreadCrumb.js";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import PropTypes from "prop-types";
import React from "react";
import SectionTitle from "../components/partials/SectionTitle/SectionTitle.js";
import { StyledGrid } from "../helpers/helpers.js";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";
import { useWindowSize } from "../helpers/helpers.js";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
  featuredImage,
  featuredImageDescription,
  nextPost,
}) => {
  const PostContent = contentComponent || Content;
  const { isMobile } = useWindowSize();

  return (
    <StyledGrid className="BlogPost">
      {helmet || ""}
      <Row>
        {!isMobile && (
          <Col xs={12}>
            <BreadCrumb
              sectionText={"Découvertes"}
              sectionUrl={"/blog/"}
              tagUrl={!!tags && !!tags.length ? `/blog/${kebabCase(tags[0])}/` : ""}
              tagText={!!tags && !!tags.length ? tags[0] : ""}
            />
          </Col>
        )}

        <Col xs={12} md={8} mdOffset={2}>
          <div className="header">
            <div className="image-container">
              <PreviewCompatibleImage
                imageInfo={featuredImage}
                className="image"
              />
              <p className="image-description">{featuredImageDescription}</p>
            </div>
            <h1 className="title">{title}</h1>
          </div>
          <div className="body">
            <PostContent content={content} />
          </div>
        </Col>

        {!!nextPost && (
          <div className='last-article'>
            <SectionTitle text="Prochain article" highlight="article" />
            <Col xs={12} md={10} mdOffset={1}>
              <ArticleCard
                isHorizontal={true}
                slug={nextPost.fields.slug}
                tags={nextPost.frontmatter.tags}
                title={nextPost.frontmatter.title}
                excerpt={nextPost.excerpt}
                image={nextPost.frontmatter.featuredImage}
              />
            </Col>
          </div>
        )}
      </Row>
    </StyledGrid>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const post = data.post;
  const nextPost = data.nextPost ? data.nextPost : null;

  return (
    <Layout>
      <BlogPostTemplate
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.html}
        contentComponent={HTMLContent}
        tags={post.frontmatter.tags}
        featuredImage={post.frontmatter.featuredImage}
        featuredImageDescription={post.frontmatter.featuredImageDescription}
        nextPost={nextPost}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $nextId: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredImageDescription
        featuredImage {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    nextPost: markdownRemark(id: { eq: $nextId }) {
      fields {
        slug
      }
      excerpt(pruneLength: 144)
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;
