import "./BreadCrumb.scss";

import { Link } from "gatsby";
import React from "react";

const BreadCrumb = ({ sectionText, sectionUrl, tagUrl, tagText }) => {
  return (
    <div className="BreadCrumb">
      <Link to={sectionUrl} className="section">
        {sectionText}
      </Link>
      <Link to={tagUrl} className="tag">
        {tagText}
      </Link>
    </div>
  );
};

export default BreadCrumb;
